<template>
  <div class="home">
    <button class="button field is-info is-light z-10" style="margin-top: 25px" @click="saveLogistic">
      <b-icon icon="check"></b-icon>
      <span>Salvar alterações</span>
    </button>

    <button class="button field is-info is-light z-10" style="margin-top: 25px; margin-left: 10px"
      @click="deleteAllLogistic">
      <b-icon icon="trash"></b-icon>
      <span>Limpar tudo</span>
    </button>

    <br />

    <b-table :data="allFilterPendingReleases" ref="table" paginated per-page="50" :striped="true"
      :debounce-search="1000" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
      aria-current-label="Current page">
      <b-table-column field="boarding_date_temp" label="Data/Hora" :searchable="true" sortable v-slot="props">
        <template>
          <a @click="props.toggleDetails(props.row)"><b-taglist>
              <b-tag type="is-dark" size="is-medium">{{ $moment(props.row.boarding_date).format('DD/MM') }}
                {{
                  $moment(props.row.boarding_time_temp).format('HH:mm')
                }}</b-tag>
            </b-taglist></a>
        </template>
      </b-table-column>

      <b-table-column field="boarding_time" label="Horário de embarque" :searchable="true" sortable v-slot="props">
        <b-field>
          <b-timepicker rounded placeholder="Selecione um horário" icon="clock" editable hour-format="24"
            v-model="props.row.boarding_time">
          </b-timepicker> </b-field></b-table-column>

      <b-table-column field="passenger.name" label="Passageiro" :searchable="true" sortable v-slot="props">{{
        props.row.passenger.name.toUpperCase() }}</b-table-column>

      <b-table-column field="type" label="Tipo" :searchable="true" sortable v-slot="props">{{
        props.row.type.toLowerCase() === 'ida' ? 'Entrada' : 'Saída'
      }}</b-table-column>

      <b-table-column field="address" label="Endereço" :searchable="true" sortable v-slot="props">
        <a
          @click="copyToClipboard(props.row.address ? props.row.address.toUpperCase() : props.row.passenger.address.toUpperCase())">{{
            props.row.address ? props.row.address.toUpperCase() : props.row.passenger.address.toUpperCase()
          }}</a></b-table-column>

      <b-table-column field="requester.name" label="Solicitante" sortable :searchable="true" v-slot="props">{{
        props.row.requester ? props.row.requester.name.toUpperCase() : 'N/A'
      }}</b-table-column>

      <b-table-column field="status" label="Veículo" sortable v-slot="props" :searchable="false">
        <b-field grouped>
          <p class="control">
            <b-button @click="removePassenger(props.row.id)" type="is-danger" icon-right="delete" />
          </p>
          <b-numberinput min="1" v-model="props.row.vehicle_number" controls-alignment="left"
            controls-position="compact"></b-numberinput>
        </b-field>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/services/store/service'
import servicePendingRelease from '../features/pending-releases/store/service'
import Service from '../models/service'

export default {
  name: 'Logistics',
  data() {
    return {
      model: Service.model,
      modelOccurrence: Service.modelOccurrence,
      isCardModalActive: false,
      isDriverCardModalActive: false,
      isPassengerModalActive: false,
      isOccurrenceCardModalActive: false,
      passengers: [],
      serviceDates: [],
      isOpen: null,
      isOpenList: null,
      activeTab: 0,
      serviceFilter: [],
      radio: null,
      showDetailIcon: null,
      allFilterPassengers: [],
      viewPassengers: [],
      viewOccurrences: [],
      allFilterPendingReleases: [],
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('pendingReleases', ['allPendingReleases']),
  },
  methods: {
    ...mapActions('pendingReleases', ['getAllPendingReleases']),
    async init() {
      await this.getAllPendingReleases()

      this.allFilterPendingReleases = this.allPendingReleases.map((x) => {
        const boarding_time = new Date()

        console.log(x.boarding_time)

        boarding_time.setHours(x.boarding_time.split(':')[0])
        boarding_time.setMinutes(x.boarding_time.split(':')[1])

        return {
          ...x,
          boarding_time: boarding_time,
          boarding_time_temp: boarding_time,
          boarding_date_temp: `${this.$moment(x.boarding_date).format(
            'DD/MM'
          )} ${this.$moment(boarding_time).format('HH:mm')}`,
        }
      })

      console.log('pending releases ', this.allFilterPendingReleases)
    },
    async saveLogistic() {
      try {
        const services = []

        console.log('all pending release ', this.allPendingReleases)

        this.allFilterPendingReleases
          .filter((x) => x.vehicle_number)
          .forEach((x) => {
            const findIndex = services.findIndex(
              (y) => y.vehicle_number === x.vehicle_number
            )

            if (findIndex > -1) {
              services[findIndex].passengers.push({
                boarding_time: this.$moment(x.boarding_time).format('HH:mm'),
                reason: x.reason,
                justification: x.justification,
                observation: x.observation,
                id: x.passenger_id,
                pending_release_id: x.id,
                additional_value: 0,
                type: x.type,
                passenger: x.passenger,
                origin_address:
                  x.type.toLowerCase() === 'ida' ? x.address : null,
                destination_address:
                  x.type.toLowerCase() === 'ida' ? null : x.address,
              })
            } else {
              const boardingDateTemp = new Date(x.boarding_date)
              boardingDateTemp.setFullYear(new Date().getFullYear())
              services.push({
                vehicle_number: x.vehicle_number,
                service_time: this.allPendingReleases.find((y) => y.id === x.id)
                  .boarding_time,
                type: x.type,
                requester_id: x.requester_id,
                service_date: x.boarding_date,
                passengers: [
                  {
                    additional_value: 0,
                    boarding_time: this.$moment(x.boarding_time).format(
                      'HH:mm'
                    ),
                    reason: x.reason,
                    justification: x.justification,
                    observation: x.observation,
                    customer_id: x.passenger.customer_id,
                    id: x.passenger_id,
                    passenger: x.passenger,
                    pending_release_id: x.id,
                    type: x.type,
                    origin_address:
                      x.type.toLowerCase() === 'ida' ? x.address : null,
                    destination_address:
                      x.type.toLowerCase() === 'ida' ? null : x.address,
                  },
                ],
              })
            }
          })

        console.log('services ', services)

        for (let service of services) {
          const listPassengerCity = []

          for (const item of service.passengers) {

            if (!item.passenger) continue;

            let location = item.passenger.location_id;

            if (location) {
              location = this.locationList.data.find((x) => x.id === location);
            }

            const city = location ? location.name : item.passenger.city;

            if (!city) continue;

            listPassengerCity.push(city.toUpperCase())
          }

          const customers = service.passengers
            .filter((x) => x.passenger && x.passenger.customer)
            .map((x) => x.passenger.customer)

          if (listPassengerCity.filter((x) => !x).length > 0) return

          let listCustomerAddress = ''
          let listPassengerAddress = ''

          listPassengerCity.forEach((x, index) => {
            if (listPassengerAddress.indexOf(x) == -1) {
              listPassengerAddress += `${x}`
            }

            if (
              index !== listPassengerCity.length - 1 &&
              listPassengerAddress.indexOf(listPassengerCity[index + 1]) == -1
            ) {
              listPassengerAddress += `, `
            }
          })

          customers.forEach((customer, index) => {
            if (listCustomerAddress.indexOf(customer.name) == -1) {
              listCustomerAddress += `${customer.name}`
            }

            if (
              index !== customers.length - 1 &&
              listCustomerAddress.indexOf(customers[index + 1].name) == -1
            ) {
              listCustomerAddress += `, `
            }
          })

          let itinerary = ''

          if (service.type === 'Ida') {
            itinerary = `${listPassengerAddress} X ${listCustomerAddress}`
          } else {
            itinerary = `${listCustomerAddress} X ${listPassengerAddress}`
          }

          service.itinerary = itinerary
        }

        await service.saveService({ service: services })
        await this.$success('Logística')
        location.reload(true)
      } catch (error) {
        this.$error(error)
      }
    },
    selectPendingRelease(index) {
      const pendingRelease = this.allFilterPendingReleases.find(
        (x) => x.id === this.passengers[index].pending_release_id
      )

      const boarding_time = new Date()

      boarding_time.setHours(pendingRelease.boarding_time.split(':')[0])
      boarding_time.setMinutes(pendingRelease.boarding_time.split(':')[1])

      this.passengers[index].boarding_time = boarding_time

      if (this.model.type === 'Ida') {
        this.passengers[index].origin_address = pendingRelease.address
        this.passengers[index].destination_address = null
      } else {
        this.passengers[index].origin_address = null
        this.passengers[index].destination_address = pendingRelease.address
      }
    },
    async removePassenger(id) {
      try {
        const result = await this.$ask('Você deseja remover o passageiro?')

        if (result.isConfirmed) {
          await servicePendingRelease.deletePendingRelease(id)

          await this.$delete('Passageiro')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    async deleteAllLogistic() {
      const result = await this.$ask(
        'Você deseja remover todos os passageiros?'
      )

      if (result.isConfirmed) {

        const promise = this.allFilterPendingReleases.map(async (x) => {
          await servicePendingRelease.deletePendingRelease(x.id)
        })

        Promise.all(promise)

        await this.$delete('Passageiros')
        location.reload(true)
      }
    },
    copyToClipboard(text) {
      const input = document.createElement('textarea')
      input.innerHTML = text
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)

      this.$buefy.toast.open(`Endereço copiado para área de transferência`)
    },
  },
}
</script>

<style scoped></style>
